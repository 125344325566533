.bg-link-water {
    background-color: var(--link-water)!important;
}

.bg-white {
    background-color: var(--white)!important;
}

.bg-midnight {
    background-color: var(--midnight)!important;
}

.bg-aqua-haze {
    background-color: var(--aqua-haze)!important;
}

.text-dark-1 {
    color: var(--midnight)!important;
}

.text-light-1 {
    color: var(--midnight-light)!important;
}

.text-light-2 {
    color: var(--white)!important;
}