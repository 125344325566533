@import './colors.mbd.scss';
@import './responsive.scss';
@import './components.mbd.scss';

.flex-1 {
    flex: 1 !important;
}

.flex-2 {
    flex: 2 !important;
}

.flex-3 {
    flex: 3 !important;
}

.vh-100 {
    height: 100vh;
}

.no-style {
    all: unset;
    background-color: var(--white);
    border-radius: 5px;
}

.cursor-pointer {
    cursor: pointer;
}

.hyphens-auto {
    hyphens: auto;
}


$start: 0;
$end: 9;

@for $i from $start through $end {
    .grid-columns-#{$i} {
        grid-template-columns: repeat(#{$i}, 1fr);
    }

    .grid-row-#{$i} {
        grid-row: #{$i};
    }

    .grid-col-#{$i} {
        grid-column: #{$i};
    }

    .translate-x-#{$i * 100} {
        -webkit-transform: translateX(calc(#{$i} * 100%)) !important;
        -moz-transform: translateX(calc(#{$i} * 100%)) !important;
        -ms-transform: translateX(calc(#{$i} * 100%)) !important;
        -o-transform: translateX(calc(#{$i} * 100%)) !important;
        transform: translateX(calc(#{$i} * 100%)) !important;
    }

    .translate-x-minus-#{$i * 100} {
        -webkit-transform: translateX(calc(-1 * #{$i} * 100%)) !important;
        -moz-transform: translateX(calc(-1 * #{$i} * 100%)) !important;
        -ms-transform: translateX(calc(-1 * #{$i} * 100%)) !important;
        -o-transform: translateX(calc(-1 * #{$i} * 100%)) !important;
        transform: translateX(calc(-1 * #{$i} * 100%)) !important;
    }
}


app-dynamic-form {
    display: block;
    width: 100%;
}


.box-sizing-border-box {
    box-sizing: border-box !important;
}