@import "./colors.mbd.scss";
@import "~@angular/cdk/overlay/_index.scss";

/**
 * INPUTS
 */

.mat-form-field {
    box-sizing: border-box !important;
}

input:-internal-autofill-selected {
    background-color: transparent !important;
    color: var(--carbon) !important;
    -webkit-box-shadow: 0 0 0 30px var(--maui-mist) inset !important;
    box-shadow: 0 0 0 30px var(--maui-mist) inset !important;
}

.pvm-form-field {
    display: block;
    position: relative;

    &>input.pvm-input,
    &>textarea.pvm-input,
    &>select.pvm-input {
        all: unset;
        width: 100%;
        z-index: 1;
        position: relative; // z-index does not work on static position
        background-color: var(--white); 
        border: none;
        border-radius: 10px;
        padding: 0.5rem 1rem;
        box-sizing: border-box;
        margin: 1rem 0;
        display: inline-block;

        &::-webkit-resizer {
            /* removes the resize icon from the bottom right corner of the text area */
            display: none;
        }

        &::placeholder {
            /* Chrome, Firefox, Opera, Safari 10.1+ */
            @extend .text-light-1;
            opacity: 1;
            /* Firefox */
        }

        &:-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            @extend .text-light-1;
        }

        &::-ms-input-placeholder {
            /* Microsoft Edge */
            @extend .text-light-1;
        }

        &:focus {
            border-width: 2px;
            padding: calc(0.5rem - 1px);
            filter: brightness(1.03);

            &~label.pvm-input-label {
                -webkit-transform: scaleY(1);
                -moz-transform: scaleY(1);
                -ms-transform: scaleY(1);
                -o-transform: scaleY(1);
                transform: scaleY(1);
            }
        }

        &:disabled {
            filter: brightness(0.9);
        }

        &~div.pvm-input-label {
            padding: inherit;
            z-index: 0;
            -webkit-transform: scaleY(0);
            -moz-transform: scaleY(0);
            -ms-transform: scaleY(0);
            -o-transform: scaleY(0);
            transform: scaleY(0);
            transform-origin: bottom;
            -webkit-transition: transform 0.2s linear 0s;
            -moz-transition: transform 0.2s linear 0s;
            -o-transition: transform 0.2s linear 0s;
            transition: transform 0.2s linear 0s;
            font-size: 0.8rem;
            position: absolute;
            top: 1rem;
            bottom: 1rem;
            left: 0;
            right: 0;

            &>span {
                display: block;
                -webkit-transform: translateY(-100%);
                -moz-transform: translateY(-100%);
                -ms-transform: translateY(-100%);
                -o-transform: translateY(-100%);
                transform: translateY(-100%);
            }

            &.pvm-input-label-active {
                -webkit-transform: scaleY(1);
                -moz-transform: scaleY(1);
                -ms-transform: scaleY(1);
                -o-transform: scaleY(1);
                transform: scaleY(1);
            }
        }

        &~div.pvm-input-error {
            padding: inherit;
            display: flex;
            align-items: flex-end;
            -webkit-transform: scaleY(0);
            -moz-transform: scaleY(0);
            -ms-transform: scaleY(0);
            -o-transform: scaleY(0);
            transform: scaleY(0);
            transform-origin: top;
            -webkit-transition: transform 0.2s linear 0s;
            -moz-transition: transform 0.2s linear 0s;
            -o-transition: transform 0.2s linear 0s;
            transition: transform 0.2s linear 0s;
            font-size: 0.8rem;
            position: absolute;
            top: 1rem;
            bottom: 1rem;
            left: 0.25rem;
            right: 0.25rem;

            &>span {
                color: var(--error);
                -webkit-transform: translateY(100%);
                -moz-transform: translateY(100%);
                -ms-transform: translateY(100%);
                -o-transform: translateY(100%);
                transform: translateY(100%);
            }

            &.pvm-input-error-active {
                -webkit-transform: scaleY(1);
                -moz-transform: scaleY(1);
                -ms-transform: scaleY(1);
                -o-transform: scaleY(1);
                transform: scaleY(1);
            }
        }
    }

    &>input.pvm-input {
        height: 2.3rem; // explicitly set because Safari date picker is slightly larger than text inputs
    }

    &>textarea.pvm-input {
        resize: vertical;
    }

    &>select.pvm-input {
        cursor: pointer;

        &:disabled {
            cursor: unset;
        }

        &::after {
            content: "";
            width: 0.8em;
            height: 0.5em;
            background-color: var(--aqua-haze);
            clip-path: polygon(100% 0%, 0 0%, 50% 100%);
        }
    }

    & div.pvm-input-error-fixed {
        padding: inherit;
        -webkit-transform: scaleX(0);
        -moz-transform: scaleX(0);
        -ms-transform: scaleX(0);
        -o-transform: scaleX(0);
        transform: scaleX(0);
        transform-origin: left;
        -webkit-transition: transform 0.2s linear 0s;
        -moz-transition: transform 0.2s linear 0s;
        -o-transition: transform 0.2s linear 0s;
        transition: transform 0.2s linear 0s;
        font-size: 0.8rem;

        &>span {
            color: var(--error);
        }

        &.pvm-input-error-fixed-active {
            -webkit-transform: scaleX(1);
            -moz-transform: scaleX(1);
            -ms-transform: scaleX(1);
            -o-transform: scaleX(1);
            transform: scaleX(1);
        }
    }
}

.toggle-wrapper,
.checkbox-wrapper,
.radio-button-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    &>input[type="checkbox"],
    &>input[type="radio"] {
        margin-right: 1rem !important; // 0.5rem
    }
}

.is-placeholder {
    color: var(--link-water) !important;
}

input.pvm-toggle[type="checkbox"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    appearance: none;
    margin: 0;

    width: 2.6em !important;
    height: 1.5em !important;
    border-radius: 0.75em;
    -webkit-transform: translateY(-0.075em);
    -moz-transform: translateY(-0.075em);
    -ms-transform: translateY(-0.075em);
    -o-transform: translateY(-0.075em);
    transform: translateY(-0.075em);

    display: flex;
    align-items: center;

    background-color: var(--midnight);
    transition: 120ms background-color ease-in-out;

    cursor: pointer;

    &::before {
        content: "";
        background-color: #ffffff;
        width: 1.2em !important;
        height: 1.2em !important;
        margin: 0 0.15em;
        border-radius: 0.6em;
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0);
        transition: 120ms transform ease-in-out;
    }

    &:checked {
        //background-color: var(--deep-loch);

        &::before {
            -webkit-transform: translateX(1.1em);
            -moz-transform: translateX(1.1em);
            -ms-transform: translateX(1.1em);
            -o-transform: translateX(1.1em);
            transform: translateX(1.1em);
        }
    }

    &:focus {
        outline: max(2px, 0.15em) solid var(--deep-loch);
        outline-offset: max(2px, 0.15em);
    }

    &:disabled {
        filter: brightness(0.9);
    }

    &~span {
        flex: 1 !important;
    }
}

input.pvm-checkbox[type="checkbox"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    appearance: none;
    margin: 0;

    font: inherit;
    color: currentColor;
    background-color: var(--catskill-white);
    width: 1.2em !important; // 1.4em
    height: 1.2em !important; // 1.4em
    border: none;
    border-radius: 1em; 
    -webkit-transform: translateY(-0.075em);
    -moz-transform: translateY(-0.075em);
    -ms-transform: translateY(-0.075em);
    -o-transform: translateY(-0.075em);
    transform: translateY(-0.075em);
    position: relative;

    cursor: pointer;

    &::before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border: none; 
        border-radius: 1em;
        -webkit-transform: scale(0);
        -moz-transform: scale(0);
        -ms-transform: scale(0);
        -o-transform: scale(0);
        transform: scale(0);
        transition: 120ms transform ease-in-out;
        background-color: var(--midnight);
    }

    &:checked::before {
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1);
    }

    &:focus {
        outline: max(2px, 0.15em) solid var(--midnight);
        outline-offset: max(2px, 0.15em);
    }

    &:disabled {
        filter: brightness(0.9);
    }

    &~span {
        flex: 1 !important;
    }
}

input.pvm-radio-button[type="radio"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    appearance: none;
    margin: 0;

    font: inherit;
    color: currentColor;
    background-color: var(--distant-horizon);
    width: 1.4em !important;
    height: 1.4em !important;
    border: 0.15em solid var(--deep-loch);
    border-radius: 0.7em;
    -webkit-transform: translateY(-0.075em);
    -moz-transform: translateY(-0.075em);
    -ms-transform: translateY(-0.075em);
    -o-transform: translateY(-0.075em);
    transform: translateY(-0.075em);
    position: relative;

    cursor: pointer;

    &::before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border: 2px solid var(--distant-horizon);
        border-radius: 1em;
        -webkit-transform: scale(0);
        -moz-transform: scale(0);
        -ms-transform: scale(0);
        -o-transform: scale(0);
        transform: scale(0);
        transition: 120ms transform ease-in-out;
        background-color: var(--deep-loch);
    }

    &:checked::before {
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1);
    }

    &:focus {
        outline: max(2px, 0.15em) solid var(--deep-loch);
        outline-offset: max(2px, 0.15em);
    }

    &:disabled {
        filter: brightness(0.9);
    }

    &~span {
        flex: 1 !important;
    }
}

.checkbox-select-wrapper,
.radio-button-select-wrapper {

    &>.checkbox-wrapper,
    &>.radio-button-wrapper {
        margin-bottom: 0.5rem;

        &:first-of-type {
            margin-top: 0.5rem;
        }

        &:last-of-type {
            margin-bottom: 0;
        }
    }
}


/**
 * BUTTONS
 */

.pvm-button {
    padding: 1rem 2rem;
    border: none !important;
    border-radius: 5px;
    cursor: pointer;
    font-weight: 400;

    &.pvm-button-medium {
        padding: 0.75rem 1.5rem;
    }

    &.pvm-button-small {
        padding: 0.5rem 1rem;
        border-radius: 14px;
    }

    &.pvm-button-secondary {
        @extend .text-dark-1;
        @extend .bg-aqua-haze;

        & * {
            @extend .text-dark-1;
        }
    }

    &.pvm-button-primary {
        @extend .text-light-2;
        @extend .bg-midnight;

        & * {
            @extend .text-light-2;
        }

        &:disabled {
            background-color: unset!important;
            @extend .text-dark-1;
        }
    }

    &.pvm-button-link {
        @extend .text-dark-1;
        background-color: transparent;
        padding: 0;

        &:disabled {
            background-color: unset !important;

            & * {
                color: var(--aqua-haze) !important;
            }
        }

        & * {
            @extend .text-dark-1;
        }
    }

    &:hover {
        filter: brightness(90%);
    }

    &:active {
        filter: brightness(70%);
    }

    &:disabled {
        filter: brightness(80%);
        cursor: not-allowed;

        &:hover,
        :active {
            filter: brightness(80%) !important;
        }
    }
}

.mbd-button {
    padding: 1rem 2rem;
    border: none !important;
    border-radius: 5px;
    cursor: pointer;
    font-weight: 400;
    background-color: #001F44 !important;

    & span {
        color: #ffffff !important;
    }

    &:disabled {
        background-color: unset !important;
        border: solid 1px #001F44 !important;
        cursor: not-allowed;

        & span {
            color: #001F44 !important;
        }
    }

}


/**
 * CARDS
 */

.pvm-card {
    @extend .bg-white;
    border-radius: 14px;
    padding: 1.5rem;
}


/**
 * BOTTOM SHEET
 */

// only for bottom sheet, not for menu
.bottom-sheet-backdrop {
    background: $overlay-backdrop-color;

    &+.cdk-global-overlay-wrapper .cdk-overlay-pane {
        flex: 1;
    }
}

mat-bottom-sheet-container {
    border-radius: 35px 35px 0 0 !important;
    line-height: 1 !important;
    flex: 1;

    &.mat-bottom-sheet-container-medium,
    &.mat-bottom-sheet-container-large,
    &.mat-bottom-sheet-container-xlarge {
        max-width: 100% !important;
    }
}