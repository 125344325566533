// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '~@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$cp-frontend-primary: mat.define-palette(mat.$indigo-palette);
$cp-frontend-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$cp-frontend-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$cp-frontend-theme: mat.define-light-theme((color: (primary: $cp-frontend-primary,
                accent: $cp-frontend-accent,
                warn: $cp-frontend-warn,
            )));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.

@include mat.all-component-themes($cp-frontend-theme);

// Custom.scss
// Option B: Include parts of Bootstrap

// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "~bootstrap/scss/functions";

// 2. Include any default variable overrides here

// 3. Include remainder of required Bootstrap stylesheets
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

// 4. Include any optional Bootstrap components as you like
@import "~bootstrap/scss/bootstrap-utilities";
// @import "~bootstrap/scss/utilities/api";
// @import "~bootstrap/scss/root";
// @import "~bootstrap/scss/reboot";
// @import "~bootstrap/scss/type";
// @import "~bootstrap/scss/images";
// @import "~bootstrap/scss/containers";
// @import "~bootstrap/scss/grid";

// 5. Add additional custom code here
@import "~bootstrap/scss/alert";


@import "~flag-icons/sass/flag-icons.scss";


// Custom Variables
@import 'theme/variables.mbd';
// Custom Common Styles
@import 'theme/common.mbd';
// Fonts
@import 'assets/fonts/fonts';

html,
body {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: scroll;
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;

    /* Firefox */
    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
        display: none;
    }
}

html {
    // prevent material bottom sheet to push html upwards
    top: 0 !important;

    background: var(--aqua-haze);
    background-position: fixed;

    -webkit-text-size-adjust: 100%;
    -moz-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
}

body {
    margin: 0;
}

* {
    font-family: 'Lato', sans-serif;
    color: var(--midnight);
}

@media (orientation: landscape) {
    body {
        // padding so that iphone notch does not block content
        padding-left: env(safe-area-inset-left);
        padding-right: env(safe-area-inset-right);
    }
}