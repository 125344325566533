@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

/*
 * Mobile (Up to Small)
 */

@mixin media-mobile {
    @include media-breakpoint-down(md) { @content; }
}


@include media-mobile {
    .d-none-mobile {
        display: none!important;
    }
}


/*
 * Desktop (From Medium Upwards)
 */

@mixin media-desktop {
    @include media-breakpoint-up(md) { @content; }
}

@include media-desktop {
    .d-none-desktop {
        display: none!important;
    }

    .auth-container {
        height: 100vh;
        display: flex;
    }
}
