:root {
    --midnight: #001F44;
    --white: #FFFFFF;
    --catskill-white: #DFE7F2;
    --link-water: #E3EBF6;
    --aqua-haze: #F1F4F7;
    --midnight-light: rgba(0, 31, 68, 0.7);



    --error: #ff0000;
    --success: #00ff00;


    --header-height: 80px;
    --navigation-height: 80px;


    --max-component-width: 1200px;
}