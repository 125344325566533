/*@font-face {
  font-family: 'Raleway';
  src: url('src/assets/fonts/Raleway') format('woff2-variations');
  font-weight: 125 950;
  font-stretch: 75% 125%;
  font-style: oblique 0deg 20deg;
 }*/

 @font-face {
  font-family: 'Raleway';
  src: url('/assets/fonts/Raleway-VariableFont_wght.ttf') format('truetype-variations');
  font-weight: 100 900;
  font-stretch: 75% 125%;
  font-style: normal;
 }

 @font-face {
  font-family: 'Lato';
  src: url('/assets/fonts/Lato-Regular.ttf') format('truetype-variations');
  font-weight: 100 900;
  font-stretch: 75% 125%;
  font-style: normal;
 }
